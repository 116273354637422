@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap');
@import "circular-std";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/aos/src/sass/aos.scss";

* {
    outline: none;
}

body {
    @apply font-circular;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: theme('colors.kakiClair');
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}  

header {
    nav {
        a {
            height: 43px;
            border-radius: 43px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 theme('spacing.4');
            white-space: nowrap;
            
            &:hover, &:active {
                transition-duration: .3s;
                transition-property: background-color;
                background-color: rgba(0, 0, 0, .2);
            }
            
            &.bt-border {
                @apply border-2 border-white border-solid;
                
                &:hover, &:active {
                    transition-duration: .3s;
                    transition-property: background-color color;
                    background-color: white;
                    color: theme('colors.kaki');
                }
            }
        }
    }
    
    a[href="#section2"] {
        bottom: 100px;
        left: theme('spacing.6');
        transform: rotate(-90deg);
        transform-origin: left;
        
        @media only screen and (min-width: 640px) {
            left: 50%;
            bottom: 60px;
            transform: rotate(-90deg) translate3d(0, -50%, 0);
        }
    }

    #suivez-nous {
        transform: rotate(-90deg);
        transform-origin: left;
    }
}

#header-slider {
    @apply relative;
    
    &::after {
        content: "";
        width: 100%;
        height: 30px;
        background: transparent url('images/mask-white.svg') no-repeat 50% 100%;
        background-size: 100% auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    
    .slick-dots {
        @apply pl-6;
        position: absolute;
        bottom: 74px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .slick-dots li {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        
        &::before {
            display: none;
        }
        
        button {
            color: transparent;
            width: 20px;
            display: block;
            height: 20px;
            border-radius: 99px;
            border: 3px solid white;
            background: transparent;
            position: relative; 
        }
        
        &.slick-active button {
            &::before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 99px;
                border: 3px solid white;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
}

.text-4-5xl{
    font-style: inherit;
}
.text-2lg{
    font-style: inherit;
}
.text-1-5xl{
    font-style: inherit;
}

#slider {
    blockquote {
        @apply text-lg font-normal leading-none;

        @screen sm {
            @apply text-3xl;
        }

        strong {
            @apply text-4-5xl inline-block font-black;

            @screen sm {
                @apply text-6xl;
            }
        }
    }

    .slick-slide {
        padding-left: theme('spacing.6');
        padding-bottom: 220px;

        @media only screen and (min-width: 768px) {
            padding-left: 360px;
            padding-bottom: 0;
        }
    }

    .bouchon {
        @apply absolute;
        bottom: -20px;
        width: 210px;
        left: 40px;
    }

    .slick-dots {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        right: theme('spacing.6');
        bottom: theme('spacing.6');
        
        @media only screen and (min-width: 768px) {
            left: 50%;
            right: auto;
            transform: translate3d(-50%, 0, 0);
        }
    }
    
    .slick-dots li {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        
        &::before {
            display: none;
        }
        
        button {
            color: transparent;
            width: 20px;
            display: block;
            height: 20px;
            border-radius: 99px;
            border: 3px solid white;
            background: transparent;
            position: relative; 
        }
        
        &.slick-active button {
            &::before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 99px;
                border: 3px solid white;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
    
    .slick-prev, .slick-next {
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        display: hidden;
        width: 41px;
        height: 41px;
        border-radius: 42px;
        border: 3px solid white;
        background-color: transparent;
        z-index: 1;
        color: transparent;
        cursor: pointer;

        @media only screen and (min-width: 768px) {
            display: block;
        }
        
        &:hover, &:active {
            transition-duration: .3s;
            transition-property: background-color;
            background-color: white;
        }
    }
    
    .slick-prev {
        &::before {
            content: "\2192";
            color: white;
            position: absolute;
            top: 7px;
            left: 50%;
            transform: translate3d(-50%, 0, 0) rotate(180deg);
            
        }
        
        &:hover, &:active {
            &::before {
                transition-duration: .3s;
                transition-property: color;
                color: theme('colors.kaki');
            }
        }
    }
    
    .slick-next {
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &::before {
            content: "\2192";
            color: white;
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }
        
        &:hover, &:active {
            &::before {
                transition-duration: .3s;
                transition-property: color;
                color: theme('colors.kaki');
            }
        }
    }
}

.mentions {
    h2 {
        @apply relative;
        
        span {
            position: relative;
            z-index: 1;
        }
        
        &::before {
            content: "";
            width: 100%;
            display: block;
            height: 2px;
            position: absolute;
            bottom: 18px;
            left: 0;
            right: 0;
            background-color: theme('colors.kakiClair');
        }
    }
}

.curve-gris {
    position: relative;
    
    &::after {
        content: "";
        width: 100%;
        height: 30px;
        background: transparent url('images/mask-grey-top.svg') no-repeat 50% 100%;
        background-size: 100% auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
}

#section4 {
    @apply relative;

    @screen lg {
        > div {
            @apply mx-auto;
            max-width: 1440px;
        }
    }
    
    &::after {
        content: "";
        width: 100%;
        height: 30px;
        background: transparent url('images/mask-grey-top.svg') no-repeat 50% 100%;
        background-size: 100% auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    h2 {
        @apply font-kanit text-2lg pb-3 leading-custom;

        @screen sm {
            font-size:2.5625rem;
        }
    }
    
    h3 {
        @apply text-orange text-2lg pb-3 font-bold;

        @screen sm {
            @apply text-3xl;
        }
    }

    p {
        @apply text-sm pb-3;

        @screen sm {
            @apply text-base;
            line-height: 1.8rem;
        }
    }

    h4 {
        @apply pt-2 pb-3 font-bold;
        font-size: 1.3125rem;
    }
    
    blockquote {
        @apply text-orange pb-3 font-bold text-sm;

        @screen sm {
            @apply pl-12;
            font-size: 1.3125rem;
        }
    }
    
    ul {
        li {
            @apply text-sm;
            position: relative;
            padding-left: 1.5em;

            @screen sm {
                @apply text-base;
            }
            
            &::before {
                content:"→";
                position: absolute;
                left: 0;
            }
        }
    }

    .bigger {
        li {
            @screen sm {
                @apply pb-3;
                font-size: 1.3125rem;
            }
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 30px;
        background: transparent url('images/mask-grey-top.svg') no-repeat 50% 100%;
        background-size: 100% auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

#section5 {
    .article {
        h2 {
            @apply font-kanit pb-3 text-2lg;

            @screen sm {
                font-size: 2.3125rem;
            }
        }

        p {
            @apply pt-2 text-sm;

            @screen sm {
                font-size: 1.3125rem;
                line-height: 1.9rem;
            }
        }
    }
}

#section6 {
    @apply relative;
    
    &::before {
        content: "";
        width: 100%;
        height: 30px;
        background: transparent url('images/mask-grey.svg') no-repeat 50% 100%;
        background-size: 100% auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    
    .article {
        br {
            display: none;
        }
        
        h3 {
            @apply font-bold text-base;
        }
        
        p {
            @apply pt-3 text-sm;
        }
        @screen sm {
            h3 {
                @apply text-1-5xl;
            }

            p {
                @apply text-base;
            }
        }
    }
}

.results a {
    cursor: pointer;
    @apply block font-bold;
}

$sizeBefore: 65px;

.number-one, .number-two {
    position: relative;

    &::before {
        @apply border-2 border-kakiClair border-solid text-3xl bg-grisClair font-kanit;
        content: "1";
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        border-radius: 999px;
        width: $sizeBefore;
        height: $sizeBefore;
        position: absolute;
        top: calc(((#{$sizeBefore} + 2px) / 2) * -1);
        left: 50%;
        margin-left: calc(((#{$sizeBefore} + 2px) / 2) * -1);
    }
}

.number-two {
    position: relative;

    &::before {
        content: "2";
    }
}

@screen sm {
    .number-one {
        &::before {
            right: calc(80px + #{$sizeBefore});
            left: auto;
        }
    }
    
    .number-two {
        &::before {
            left: calc(80px + #{$sizeBefore});
        }
    }
}


.separator {
    width: 1px;
    height: 20%;
    @apply bg-orange;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate3d(0, 50%, 0);
}

@screen md {
    .separator {
        width: 20%;
        height: 1px;
        @apply bg-orange;
        position: absolute;
        right: 0;
        left: auto;
        bottom: auto;
        top: 102px;
        transform: translate3d(50%, 0, 0);
    }
}

.elc-header{
    margin-bottom: 30px;
}